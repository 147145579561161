import React, { useEffect } from 'react'
import { useLazyQuery } from '@apollo/client'
import type { Maybe } from "../graphql/__generated__"

import { getStockCover, isCustomCover } from '../utils/Covers'
import { CustomCoversDocument, StockDocument } from '../graphql/__generated__'

type CoverImageProps = {
  coverId: Maybe<string>
  size?: number
  className?: string
}

export default function CoverImage({ coverId, size, className }: CoverImageProps) {
  let width = size || 5
  let height = size || 5

  // TODO: handle loading/error states from GQL queries

  const [
    loadStock,
    { data: stockData, loading: loadingStock, error: loadingError },
  ] = useLazyQuery(StockDocument)

  const [
    loadCustom,
    { data: customCoversData, loading: loadingCustomCovers, error: customCoversError },
  ] = useLazyQuery(CustomCoversDocument)

  useEffect(() => {
    if (!coverId) return

    if (isCustomCover(coverId)) {
      loadCustom()
    } else {
      loadStock()
    }
  }, [coverId])

  if (!coverId) {
    return <></>
  }

  const isCustom = isCustomCover(coverId)

  let name: string | undefined = undefined
  let url: string | undefined = undefined
  let showBorder: boolean = false

  if (isCustom) {
    if (!customCoversData) {
      return <></>
    }

    const { customCovers } = customCoversData

    for (let i=0; i < customCovers.length; i++){
      if (customCovers[i].id === coverId) {
        url = customCovers[i].thumbnailImageURL
        name = "Custom Cover"
        showBorder = true

        if (customCovers[i].isFull) {
          // This arcane formula is brought to you by the width of the spine (1.65cm)
          // and the width of the cover (17.5cm)
          width = width * 2 + width * (1.65/17.5)
        }

        break
      }
    }
  } else {
    if (!stockData) {
      return <></>
    }

    const cover = getStockCover(stockData.stock, coverId)
    if (!cover || !cover?.images) {
      return <div>Cover not found.</div>
    }

    url = cover.images[0] || ''
    name = cover.displayName
  }

  if (!url || !name) {
    return <></>
  }

  return (
    <div className={className}>
      <div
        style={{
          backgroundImage: `url("${ url }")`,
          width: `${ width }rem`,
          height: `${ height }rem`
        }}
        title={`Book Cover: ${ name }`}
        className={`bg-cover bg-center bg-no-repeat ${ showBorder ? 'border-gray-50 border shadow' : '' }`}
      />
    </div>
  )
}
