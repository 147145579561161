import React, { useRef } from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'

import './App.css'
import Orders from './pages/Orders'
import DesignEditor from './pages/DesignEditor'
import AddressEditor from './pages/AddressEditor'
import CoverEditor from './pages/CoverEditor'
import MessageEditor from './pages/MessageEditor'
import OrderSummary from './pages/OrderSummary'
import Checkout from './pages/Checkout'
import Onboarding from './pages/Onboarding'
import LocalWelcome from './pages/LocalWelcome'
import OrientationSelect from './pages/OrientationSelect'
import DownloadDesign from './pages/DownloadDesign'
import TransferDesign from './pages/TransferDesign'
import AssembleBook from './pages/AssembleBook'
import GiftSelect from './pages/GiftSelect'
import SendEGiftCard from './pages/SendEGiftCard'
import CustomCoverDesigner from './pages/CustomCoverDesigner'
import Help from './pages/Help'
import Contact from './pages/Contact'
import AuthLinkLogin from './components/AuthLinkLogin'
import ScrollToTop from './components/ScrollToTop'
import Header from './components/Header'
import Footer from './components/Footer'
import StepContextProvider from './components/StepContext'
import SudoAndRedirect from './components/SudoAndRedirect'
import NewDesignSelection from './pages/NewDesignSelection'
import CloneDesignToNewOrder from './pages/CloneDesignToNewOrder'
import BoxEditor from './pages/BoxEditor'
import PreorderRefundListing from './pages/PreorderRefundListing'
import PreorderRefund from './pages/PreorderRefund'
import ReportIssue from './pages/ReportIssue'
import ReportOrderIssue from './pages/ReportOrderIssue'

function App() {
  // This allows users to drop files anywhere on the page to upload them.
  const pageRef = useRef(null)

  Sentry.init({
    dsn: "https://7891f6e12ceb42abb30c604b9f872cb3@o509038.ingest.sentry.io/4504238420131840",
    integrations: [new BrowserTracing()],
    denyUrls: ['localhost', '127.0.0.1', 'local.sendheirloom.com'],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  })

  return (
  <Sentry.ErrorBoundary>
    <Router>
      <div ref={ pageRef } className="min-h-screen font-sans">
        <StepContextProvider>
          <Header />

          <main className="p-2 md:p-6 lg:p-10 bg-white rounded shadow-xl max-w-[960px] min-h-[8rem] m-auto flex flex-col justify-center">
            <Routes>
              <Route path="/" element={<Onboarding />} />
              <Route path="/code/:codeVendor" element={<Onboarding />} />
              <Route path="/code/:codeVendor/:presetCodes" element={<Onboarding />} />

              <Route path="/orders/history" element={<Orders />} />
              <Route path="/orders/draft" element={<Orders drafts />} />
              <Route path="/design/:cloneDesignId/clone" element={<CloneDesignToNewOrder />} />
              <Route path="/designs/clone" element={<CloneDesignToNewOrder />} />

              <Route path="/order/:orderId/cover" element={<CoverEditor />} />
              <Route path="/order/:orderId/design/:designId/cover" element={<CoverEditor />} />
              <Route path="/order/:orderId/design/:designId/destination/:destinationId/cover" element={<CoverEditor />} />
              <Route path="/order/:orderId/design" element={<DesignEditor dropContainer={ pageRef } />} />
              <Route path="/order/:orderId/design/add" element={<NewDesignSelection />} />
              <Route path="/order/:orderId/design/:designId" element={<DesignEditor dropContainer={ pageRef } />} />
              <Route path="/order/:orderId/design/:designId/destination/:destinationId" element={<DesignEditor dropContainer={ pageRef } />} />
              <Route path="/order/:orderId/address" element={<AddressEditor />} />
              <Route path="/order/:orderId/design/:designId/address" element={<AddressEditor />} />
              <Route path="/order/:orderId/design/:designId/destination/:destinationId/address" element={<AddressEditor />} />
              <Route path="/order/:orderId/message" element={<MessageEditor />} />
              <Route path="/order/:orderId/design/:designId/message" element={<MessageEditor />} />
              <Route path="/order/:orderId/design/:designId/destination/:destinationId/message" element={<MessageEditor />} />
              <Route path="/order/:orderId/design/:designId/destination/:destinationId/message/cover" element={<MessageEditor />} />
              <Route path="/order/:orderId/box" element={<BoxEditor />} />
              <Route path="/order/:orderId/design/:designId/box" element={<BoxEditor />} />
              <Route path="/order/:orderId/design/:designId/destination/:destinationId/box" element={<BoxEditor />} />
              <Route path="/order/:orderId/payment" element={<Checkout />} />
              <Route path="/order/:orderId" element={<OrderSummary />} />
              <Route path="/order/:orderId/status" element={<OrderSummary showSteps={ false } />} />

              <Route path="/gift/order/:orderId/select" element={<GiftSelect />} />

              { /* Gifts still need to have designs, as we don't have a way to have a destination not owned by a design */ }
              <Route path="/gift/order/:orderId/cover" element={<CoverEditor isGift />} />
              <Route path="/gift/order/:orderId/design/:designId/cover" element={<CoverEditor isGift />} />
              <Route path="/gift/order/:orderId/design/:designId/destination/:destinationId/cover" element={<CoverEditor isGift />} />
              <Route path="/gift/order/:orderId/address" element={<AddressEditor isGift />} />
              <Route path="/gift/order/:orderId/design/:designId/address" element={<AddressEditor isGift />} />
              <Route path="/gift/order/:orderId/design/:designId/destination/:destinationId/address" element={<AddressEditor isGift />} />
              <Route path="/gift/order/:orderId/message" element={<MessageEditor isGift />} />
              <Route path="/gift/order/:orderId/design/:designId/message" element={<MessageEditor isGift />} />
              <Route path="/gift/order/:orderId/design/:designId/destination/:destinationId/message" element={<MessageEditor isGift />} />
              <Route path="/gift/order/:orderId/box" element={<BoxEditor isGift />} />
              <Route path="/gift/order/:orderId/design/:designId/box" element={<BoxEditor isGift />} />
              <Route path="/gift/order/:orderId/design/:designId/destination/:destinationId/box" element={<BoxEditor isGift />} />
              <Route path="/gift/order/:orderId/payment" element={<Checkout isGift />} />
              <Route path="/gift/order/:orderId" element={<OrderSummary isGift />} />

              <Route path="/e-gift/order/:orderId/email" element={<SendEGiftCard />} />
              <Route path="/e-gift/order/:orderId/design/:designId/email" element={<SendEGiftCard />} />
              <Route path="/e-gift/order/:orderId/design/:designId/destination/:destinationId/email" element={<SendEGiftCard />} />
              <Route path="/e-gift/order/:orderId/message" element={<MessageEditor isEGift />} />
              <Route path="/e-gift/order/:orderId/design/:designId/message" element={<MessageEditor isEGift />} />
              <Route path="/e-gift/order/:orderId/design/:designId/destination/:destinationId/message" element={<MessageEditor isEGift />} />
              <Route path="/e-gift/order/:orderId/payment" element={<Checkout isEGift />} />
              <Route path="/e-gift/order/:orderId" element={<OrderSummary isEGift />} />
              <Route path="/e-gift/order/:orderId/status" element={<OrderSummary isEGift showSteps={ false } />} />

              { /* CustomCoverDesigner works w/ and w/o Steps; since this doesn't implement
                 * DestComponentEditor, we require order/design/destination IDs when using Steps. */ }
              <Route path="/cover" element={<CustomCoverDesigner />} />
              <Route path="/cover/:coverId" element={<CustomCoverDesigner />} />
              <Route path="/order/:orderId/design/:designId/destination/:destinationId/cover/back/new" element={<CustomCoverDesigner face="back" />} />
              <Route path="/order/:orderId/design/:designId/destination/:destinationId/cover/back/:coverId" element={<CustomCoverDesigner face="back" />} />
              <Route path="/order/:orderId/design/:designId/destination/:destinationId/cover/new" element={<CustomCoverDesigner face="front" />} />
              <Route path="/order/:orderId/design/:designId/destination/:destinationId/cover/:coverId" element={<CustomCoverDesigner face="front" />} />
              <Route path="/order/:orderId/design/:designId/destination/:destinationId/message/cover/new" element={<CustomCoverDesigner face="inside" />} />
              <Route path="/order/:orderId/design/:designId/destination/:destinationId/message/cover/:coverId" element={<CustomCoverDesigner face="inside" />} />
              <Route path="/gift/order/:orderId/design/:designId/destination/:destinationId/cover/back/new" element={<CustomCoverDesigner isGift face="back" />} />
              <Route path="/gift/order/:orderId/design/:designId/destination/:destinationId/cover/back/:coverId" element={<CustomCoverDesigner isGift face="back" />} />
              <Route path="/gift/order/:orderId/design/:designId/destination/:destinationId/cover/new" element={<CustomCoverDesigner isGift face="front" />} />
              <Route path="/gift/order/:orderId/design/:designId/destination/:destinationId/cover/:coverId" element={<CustomCoverDesigner isGift face="front" />} />
              <Route path="/gift/order/:orderId/design/:designId/destination/:destinationId/message/cover/new" element={<CustomCoverDesigner isGift face="inside" />} />
              <Route path="/gift/order/:orderId/design/:designId/destination/:destinationId/message/cover/:coverId" element={<CustomCoverDesigner isGift face="inside" />} />

              <Route path="/help" element={<Help />} />
              <Route path="/help/contact" element={<Contact />} />
              <Route path="/help/preorder/refund" element={<PreorderRefundListing />} />
              <Route path="/help/preorder/refund/:balanceEntryId" element={<PreorderRefund />} />
              <Route path="/help/issue/report" element={<ReportIssue />} />
              <Route path="/help/order/issue/report" element={<ReportOrderIssue />} />
              <Route path="/help/order/:orderId/issue/report" element={<ReportOrderIssue />} />
              <Route path="/help/order/:orderId/issue/report/:issueType" element={<ReportOrderIssue />} />

              <Route path="/local" element={<LocalWelcome />} />
              <Route path="/local/design" element={<OrientationSelect />} />
              <Route path="/local/design/:designId" element={<OrientationSelect />} />
              <Route path="/local/design/:designId/media" element={<DesignEditor dropContainer={ pageRef } />} />
              <Route path="/local/design/:designId/download" element={<DownloadDesign />} />
              <Route path="/local/design/:designId/transfer" element={<TransferDesign />} />
              <Route path="/local/design/:designId/assemble" element={<AssembleBook />} />

              <Route path="/auth/link" element={<AuthLinkLogin />} />
              <Route path="/sudo/reset" element={<SudoAndRedirect />} />
              <Route path="/sudo/:email" element={<SudoAndRedirect />} />
            </Routes>
          </main>
        </StepContextProvider>

        <Footer />

        <ScrollToTop />
      </div>
    </Router>
  </Sentry.ErrorBoundary>
  )
}

export default App
