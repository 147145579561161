import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useState } from 'react'
import { useMutation } from '@apollo/client'
import { FormProvider, useForm } from 'react-hook-form'
import GiftMessageInput from './GiftMessageInput'
import type { FormRef, DestFormProps } from './DestComponentEditor'
import { UpdateGiftMessageDocument, UpdateInsideCoverDocument, UpdateInsideCoverMutation } from '../graphql/__generated__'
import type { UpdateGiftMessageMutation } from '../graphql/__generated__'
import type { MessageFormFields } from './GiftMessageInput'

export type MessageFormOptions = {
  isEGift?: boolean;
  onCustomCoverEdit?: (customCoverId: string) => void
  choice: 'message' | 'custom' | null
  setChoice: React.Dispatch<React.SetStateAction<'message' | 'custom' | null>>
}

const MessageForm = forwardRef<FormRef, DestFormProps>(({ order, options, destination, onSave, onError, stock }, formRef) => {
  const [
    updateGiftMessage,
    { loading: savingMessage, error: saveMessageError },
  ] = useMutation<UpdateGiftMessageMutation>(UpdateGiftMessageDocument)
  const [
    updateInsideCover,
    { loading: savingCover, error: saveCoverError },
  ] = useMutation<UpdateInsideCoverMutation>(UpdateInsideCoverDocument)

  const [error, setError] = useState<string | undefined>();
  useEffect(() => setError(undefined), [options.choice])

  const submit = async function (values: MessageFormFields) {
    if (!destination?.id) {
      throw new Error("No destination provided")
    }

    const giftMessage = options.isEGift || options.choice === 'message' ? values.giftMessage : { body: "" }
    const customCover = options.choice === 'custom' ? values.coverId : undefined;

    if (options.choice === 'custom' && !customCover) {
      const errMessage = 'Please select a custom cover.'
      setError(errMessage)
      onError?.(errMessage)
      return
    }

    const mutations: Promise<any>[] = []
    if (giftMessage?.body !== destination.giftMessage?.body) {
      mutations.push(updateGiftMessage({
        variables: {
          destinationId: destination.id,
          message: giftMessage,
        },
      }))
    }
    if (customCover !== destination.insideCoverId) {
      mutations.push(updateInsideCover({
        variables: {
          destinationId: destination.id,
          coverId: customCover ?? '',
        },
      }))
    }

    try {
      await Promise.all(mutations)
      onSave?.(destination)
    } catch (err: any) {
      onError?.(err.message)
    }
  }

  const methods = useForm<MessageFormFields>()
  const { handleSubmit, reset } = methods

  const { giftMessage } = destination ?? {}
  useEffect(() => reset({ giftMessage }), [reset, giftMessage])

  const errorHandler = useCallback(
    () => onError?.("Check validation errors on form"),
    [onError],
  )

  useImperativeHandle(formRef, () => ({
    saving: savingMessage || savingCover,
    submit: handleSubmit(submit, errorHandler),
  }), [savingMessage, savingCover, errorHandler])

  return <div className="mx-auto md:mx-4 space-y-4">
    <FormProvider { ...methods }>
      <form onSubmit={ handleSubmit(submit, errorHandler) }>
        <GiftMessageInput
          stock={ stock }
          destination={ destination }
          { ...options }
        />
        { !!(saveMessageError || saveCoverError) && <p className="p-2 m-4 bg-red-300">Error saving, please try again.</p> }
        { !!error && <p className="p-2 m-4 bg-red-300">{ error }</p> }
      </form>
    </FormProvider>
  </div>
})

export default MessageForm
